import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { Search, Stamp2, ArrowDown } from '../components/shared/icons'
import { CollectionSlider } from '../components/shared/sliders'
import { InstagramText, SearchResults } from '../components/recipe'

import {
  HeroBlock,
  Decoration,
  FeaturedItemBlock,
  FeaturedArticlesBlock,
  Space,
  SEO,
  LinkType,
  RichText,
} from '../components/shared'

import {
  mobileVw,
  desktopVw,
  fonts,
  colors,
  desktopBreakpoint,
  letterSpacing,
} from '../styles'
import { useLang, useLocale, useDictionaryQuery } from '../hooks'

const RecipeCollection = ({
  location,
  data: { contentfulPageRecipeLanding, contentfulGlobalSite },
}) => {

  const lang = useLang();
  const locale = useLocale();

  const {
    name,
    heroblock,
    searchFieldText,
    recipeCollections,
    featuredItem,
    decorations,
    titleRecipeCollection,
    relatedArticles,
    seoDescription,
    seoTitle,
    articleCategory,
    bottomBrush,
    longDescription,
    longDescriptionTitle,
    longRichDescription,
  } = contentfulPageRecipeLanding

  const {
    defaultTestimonialImage,
    blackBrushesRecipeLandings,
  } = contentfulGlobalSite

  let description
  if (!!longDescription) {
    description = longDescription.longDescription
  }

  const [menu, setMenu] = useState(0)
  const { searchBarText } = useDictionaryQuery()

  const [filterMenu, setFilterMenu] = useState(false)

  const [query, updateQuery] = useState('')

  const refContainer = useRef(null)

  const focusMenu = () => {
    {
      menu ? refContainer.current.focus() : null
    }
    viewDetails()
  }

  const handleClose = (i) => {
    setMenu(i)
    setFilterMenu(false)
  }

  const viewDetails = () => {
    {
      menu
        ? window.scrollTo({
          top: refContainer.current.offsetTop - 100,
          behavior: 'smooth',
        })
        : null
    }
  }

  useEffect(() => {
    focusMenu()
  }, [menu])

  const title = titleRecipeCollection ? titleRecipeCollection : 'Explorez un monde de saveurs...'
  const christmans = name.split(' ')[0]

  return (
    <StyledRecipeCollection>
      <SEO
        description={seoDescription}
        location={location}
        title={seoTitle}
        pageType='Recipe landing'
      />
      <Background christmas={christmans === "Christmas" && true}>
        <Wrapper>
          <HeroBlock
            zIndex={2}
            data={{ ...heroblock, marginBottom: '[0, 0]' }}
            marginTitle={[50]}
          />
        </Wrapper>
        <StyledDecoration christmas={christmans === "Christmas" && true}>
          {bottomBrush ?
            <Decoration data={bottomBrush} />
            :
            (
              blackBrushesRecipeLandings &&
              blackBrushesRecipeLandings.map((item, i) => {
                return <Decoration key={i} data={item} />
              })
            )
          }
        </StyledDecoration>
      </Background>
      {christmans === "Christmas" &&
        <div className="Abc">
          {decorations.map((item, i) => {
            return <Decoration key={i} data={item} />
          })}
        </div>
      }
      <Container christmas={christmans === "Christmas" && true}>
        {searchFieldText ? <StyledSpan>{searchFieldText}</StyledSpan> : <StyledSpanTitle>{longDescriptionTitle}</StyledSpanTitle>}
        {christmans === "Christmas" ?
          <StyledSpanDescription>
            {/* <span>{description}</span> */}
            <RichText json={longRichDescription} />
            <Space l={4} s={3} />
          </StyledSpanDescription>
          :
          <>
            <SearchBar>
              <StyledInput
                placeholder={searchBarText}
                onChange={(e) => updateQuery(e.target.value)}
              />
              <SearchIcon />
            </SearchBar>
          </>
        }
        <StyledTitle>
          {title.split(' ').map((word, i) => (
            <span key={i}>{word}</span>
          ))}
        </StyledTitle>
        <SearchResults search={query} />
        <div style={query ? { display: 'none' } : { display: 'block' }}>
          <StyledButton
            onClick={() => setFilterMenu(!filterMenu)}
            className='mobileOnly'
          >
            Menu
            <StyledArrow />
          </StyledButton>
          <Menu display={!!filterMenu}>
            {recipeCollections && recipeCollections.map(({ title }, i) => (
              <CategoryMenu
                key={i}
                onClick={() => handleClose(i)}
                selected={i === menu}
              >
                {title}
              </CategoryMenu>
            ))}
          </Menu>
        </div>
      </Container>
      <div style={query ? { display: 'none' } : { display: 'block' }}>
        {recipeCollections && recipeCollections.map((collection, i) => (
          <CollectionSlider
            key={i}
            menu={menu}
            index={i}
            refContainer={refContainer}
            titleHeading={collection.title}
            slides={collection.slides}
          />
        ))}
      </div>
      {featuredItem &&
        <FeaturedItemWrapper>
          <FeaturedItemBlock
            data={featuredItem}
            title={featuredItem.title}
            hideRating
            link={`/${lang}${featuredItem.featuredItem.slug}`}
            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${featuredItem.title}`}
          />
        </FeaturedItemWrapper>
      }
      {christmans !== "Christmas" &&
        <>
          <Image
            className='mobileOnly'
            fluid={decorations[0].image.fluid}
            alt={decorations[0].image.title}
          />
          <Image
            className='desktopOnly'
            fluid={decorations[1].image.fluid}
            alt={decorations[1].image.title}
          />
        </>
      }
      <ArticleWrapper>
        <div>
          {articleCategory && articleCategory.map((collection, i) => (
            <CollectionSlider
              key={i}
              index={i}
              titleHeading={collection.title}
              slides={collection.slides}
            />
          ))}
        </div>
        <Space l={4} s={3} />
      </ArticleWrapper>
      {
        locale === 'fr' &&
        <>
          <InstagramWrapper>
            <ImageWrapper style={{ position: 'relative' }}>
              <StyledImage
                fluid={defaultTestimonialImage.fluid}
                alt={defaultTestimonialImage.title}
              />
              <StampIcon />
            </ImageWrapper>
            <InstagramText />
          </InstagramWrapper>
        </>
      }
      {
        christmans !== "Christmas" &&
        <>
          <BlackBlock />
          <Image
            className='mobileOnly'
            fluid={decorations[2].image.fluid}
            alt={decorations[2].image.title}
          />
          <Image
            className='desktopOnly'
            fluid={decorations[3].image.fluid}
            alt={decorations[3].image.title}
          />
        </>
      }
    </StyledRecipeCollection>
  )
}

const StyledRecipeCollection = styled.div`
  overflow-x: hidden;
  @media (min-width: ${desktopBreakpoint}) {
    h1 {
      padding-right: ${desktopVw(20)};
    }
  }
`

const StyledArrow = styled(ArrowDown)`
  width: ${mobileVw(8)};
  height: auto;
  stroke: ${colors.black};
  margin-left: ${mobileVw(5)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(8)};
    margin-left: ${desktopVw(15)};
    margin-bottom: ${desktopVw(2)};
  }
`

const Wrapper = styled.div`
  h2 {
    font-size: ${mobileVw(33)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    h2 {
      font-size: ${desktopVw(70)};
    }
  }
`

const Container = styled.div`
  padding: ${mobileVw(30)} ${mobileVw(16)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(128)};
    position: ${(props) => (props.christmas ? `relative` : `unset`)};
  }
`

const StyledSpan = styled.h2`
  text-transform: uppercase;
  font-size: ${mobileVw(20)};
  letter-spacing: ${mobileVw(2)};
  margin-bottom: ${mobileVw(8)};

  span{
    font-size: ${mobileVw(16)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(28)};
    letter-spacing: ${desktopVw(2.8)};
    margin-bottom: ${desktopVw(19)};

    span{
      font-size: ${desktopVw(24)};  
    }
  }
`
const StyledSpanTitle = styled.h2`
  text-transform: uppercase;
  font-size: ${mobileVw(20)};
  letter-spacing: ${mobileVw(2)};
  margin-bottom: ${mobileVw(8)};
  color: ${colors.mailleGold};
  span{
    font-size: ${mobileVw(16)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(30)};
    color: ${colors.mailleGold};
    letter-spacing: ${desktopVw(2.8)};
    margin-bottom: ${desktopVw(19)};

    span{
      font-size: ${desktopVw(24)};  
    }
  }
`
const StyledSpanDescription = styled.div`
font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(24)};
  margin: ${(props) => mobileVw(props.marginMobile || 50)} 0 0;
  z-index: 2;

  @media (min-width: ${desktopBreakpoint}) {
    position: relative;
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(24)};
    margin: ${(props) => desktopVw(props.margin || 50)} ${desktopVw(95)} 0
      ${desktopVw(0)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  h3 {
    font-family: ${fonts.splandor};
    margin: 0 0 ${mobileVw(8)} 0;
    font-size: ${mobileVw(30)};

    @media (min-width: ${desktopBreakpoint}) {
      margin: 0 0 ${desktopVw(15)} 0;
      font-size: ${desktopVw(30)};
    }
  }

`
const StyledTitle = styled.h2`
  color: ${colors.black};
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(25)};
  padding-top: ${mobileVw(20)};

  span {
    padding-right: ${mobileVw(5)};

    &:first-of-type {
      color: ${colors.mailleGold};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    padding: ${desktopVw(20)} 0;

    span {
      padding-right: ${desktopVw(8)};
    }
  }
`

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(48)};
  }
`

const StyledInput = styled.input`
  height: ${mobileVw(40)};
  border: solid ${mobileVw(1)} ${colors.black};
  width: 100%;
  font-size: ${mobileVw(14)};
  padding: ${mobileVw(15)};
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(14)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(56)};
    border: solid ${desktopVw(1)} ${colors.black};
    padding: ${desktopVw(15)};
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
  }
`

const SearchIcon = styled(Search)`
  position: absolute;
  right: ${mobileVw(20)};
  height: auto;
  cursor: pointer;

  @media (min-width: ${desktopBreakpoint}) {
    right: ${desktopVw(20)};
    bottom: ${desktopVw(25)};
  }
`

const FeaturedItemWrapper = styled.div`
  margin: ${mobileVw(30)} auto;
  padding: 0 ${mobileVw(16)};

  button {
    display: none;
  }
  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(50)} auto;
    padding: 0 ${desktopVw(260)};

    .featured-image {
      margin-top: 0;
      width: 100%;
      max-height: ${desktopVw(450)};
      img {
        object-position: center center !important;
      }
    }

    span {
      padding-right: ${desktopVw(8)};
    }
  }
`

const ArticleWrapper = styled.div`
  background-color: ${colors.black};
  @media (min-width: ${desktopBreakpoint}) {
    padding-left: ${desktopVw(128)};
  }
`

const BlackBlock = styled.div`
  background-color: ${colors.black};
  height: ${mobileVw(45)};
  margin-bottom: ${mobileVw(-45)};
`

const StyledImage = styled(Image)`
  @media (min-width: ${desktopBreakpoint}) {
    min-width: ${desktopVw(763)};
    height: ${desktopVw(528)};
  }
`

const StampIcon = styled(Stamp2)`
  z-index: 1;
  position: absolute;
  left: ${mobileVw(20)};
  bottom: ${mobileVw(-40)};
  width: ${mobileVw(96)};
  height: ${mobileVw(96)};

  @media (min-width: ${desktopBreakpoint}) {
    left: 91%;
    top: ${desktopVw(-50)};
    width: ${desktopVw(160)};
    height: ${desktopVw(160)};
  }
`

const ImageWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    height: 100%;
  }
`

const InstagramWrapper = styled.div`
  background-color: ${colors.black};

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    align-items: center;
    max-height: ${desktopVw(528)};
  }
`

const CategoryMenu = styled.button`
  cursor: pointer;
  display: block;
  margin: ${mobileVw(5)} ${mobileVw(15)} 0 0;
  padding: ${mobileVw(4)};
  line-height: ${mobileVw(20)};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(2.2)};
  border-radius: ${mobileVw(10)};
  background-color: ${(props) =>
    props.selected ? `${colors.mailleGold}` : `inherit`};
  color: ${(props) => (props.selected ? `${colors.white}` : `${colors.black}`)};
  font-size: ${mobileVw(18)};
  font-family: ${fonts.tivoli};
  @media (min-width: ${desktopBreakpoint}) {
    display: inline-block;
    margin-right: ${desktopVw(24)};
    margin-bottom: 0;
    line-height: ${desktopVw(24)};
    letter-spacing: ${desktopVw(2.2)};
    background-color: inherit;
    padding: 0;
    border-radius: 0;
    color: ${(props) =>
    props.selected ? `${colors.mailleGold}` : `${colors.black}`};
    border-bottom: ${(props) =>
    props.selected
      ? `solid ${desktopVw(1.5)} ${colors.mailleGold}`
      : `solid ${desktopVw(1.5)} ${colors.white}`};
    font-size: ${desktopVw(22)};
  }
`

const StyledDecoration = styled.div`
  position: relative;
  background: ${(props) => (props.christmas ? `none` : `${colors.black}`)} ;
  margin-bottom: ${desktopVw(50)};
  padding-top: ${mobileVw(85)};
  margin-bottom: ${mobileVw(35)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${desktopVw(120)};
    margin-bottom: ${desktopVw(135)};
  }
`

const Menu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['display'].includes(prop),
})`
  display: ${(props) => (props.display ? `block` : `none`)};

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
  }
`

const StyledButton = styled.button`
  margin-top: ${mobileVw(10)};
  width: 100%;
  background-color: ${colors.mediumGrey};
  padding: ${mobileVw(22)} 0;
  text-transform: uppercase;
  font-size: ${mobileVw(22)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
`

const Background = styled.div`

  background: ${(props) => (props.christmas ? `none` : `black`)};
`

export const recipeCollection = graphql`
  query RecipeLanding($id: String!, $node_locale: String!) {
    contentfulPageRecipeLanding(id: { eq: $id }) {
      seoDescription
      seoTitle
      id
      name
      decorations {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      titleRecipeCollection
      recipeCollections {
        title
        name
        slides {
          ... on ContentfulPageRecipeCollection {
            tags
            slug
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ... on ContentfulPageRecipe{
            title
            slug
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      featuredItem {
        title
        buttonText
        featuredItem {
          ... on ContentfulPageRecipe {
            id
            name
            slug
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            title
          }
        }
      }
      searchFieldText
      longRichDescription{
        raw
      }
      longDescriptionTitle
      longDescription {
        longDescription
      }
      heroblock {
        video {
          name
          poster {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          videoMov {
            file {
              url
            }
          }
          videoMp4 {
            file {
              url
            }
          }
        }
        title
        marginBottom
        marginDescription
        background
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        textSlider {
          title
          link {
            link {
              ...linkTarget
            }
          }
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      relatedArticles {
        createdAt
        date(formatString: "DD-MM-YYYY")
        author
        subtitle
        title
        slug
        category
        thumbnailImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      bottomBrush {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      #Fetch Article Category(section) for inspiration culinaries page-Start
      articleCategory {
        title
        slides {
          slug
          thumbnailImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title:name
        }
      }
    }
    #Fetch Article Category(section) for inspiration culinaries page-End

    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      blackBrushesRecipeLandings {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      defaultTestimonialImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default RecipeCollection
